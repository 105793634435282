import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#src/constants/query";
import env from "#src/env";
import { appBaseQuery } from "#src/features/common/baseQuery";
import {
  GetOauthUrlRequest,
  GetOauthUrlResponse,
  GetProvidersStatus,
} from "#src/types/oauth";
import createApi from "../../../createApi";

export const oauthApi = createApi({
  reducerPath: "oauth",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/oauth/`,
  }),
  tagTypes: [QUERY_TAGS.OauthStatus],
  endpoints: (builder) => ({
    fetchProvidersStatus: builder.query<GetProvidersStatus, number>({
      query: (organisationId) => ({
        url: "status",
        method: "GET",
        headers: getHeaders(),
        params: {
          organisationId,
        },
        credentials: "include",
      }),
      providesTags: (_res, _error, organisationId) => [
        { type: QUERY_TAGS.OauthStatus, id: organisationId },
      ],
    }),
    connectExternalService: builder.mutation<void, GetOauthUrlRequest>({
      async queryFn({ provider, ...params }, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `${provider}/auth`,
          method: "GET",
          params,
          headers: getHeaders(),
          credentials: "include",
        });

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        if (response.data) {
          window.open((response.data as GetOauthUrlResponse).url, "_self");
        }

        return { data: undefined };
      },
    }),
    disconnectExternalService: builder.mutation<void, GetOauthUrlRequest>({
      query: ({ organisationId, provider }) => ({
        url: `${provider}/disconnect`,
        method: "POST",
        headers: getHeaders(),
        body: {
          organisationId,
        },
        credentials: "include",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          oauthApi.util.invalidateTags([
            {
              type: QUERY_TAGS.OauthStatus,
              id: args.organisationId,
            },
          ])
        );
      },
    }),
  }),
});

export const {
  useFetchProvidersStatusQuery,
  useConnectExternalServiceMutation,
  useDisconnectExternalServiceMutation,
} = oauthApi;
